'use client';

import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { formatDate } from '@/lib/utils';
import Link from 'next/link';

const Header = ({ logo }: { logo?: string }) => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    // update the clock every minute
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 60_000);
    return () => clearInterval(intervalId);
  }, []);

  const currentTime = formatDate(now);

  return (
    <header className={'header'}>
      <Link href={'/'} className={'logo-container'}>
        <img
          src={!!logo ? logo : 'https://www.reconv.in/logo.webp'}
          alt="Company Logo"
          width={120}
          height={40}
          className={'logo'}
        />
        <p className={'logo-title'}>Meet</p>
      </Link>
      <p className={`hidden sm:block`}>{currentTime}</p>
    </header>
  );
};

export default Header;
